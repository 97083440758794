/* You can add global styles to this file, and also import other style files */
@import './hvs-theme.scss';
@import './hvs-icons.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  scroll-behavior: auto;
}

body {
  margin: 0;
  @apply font-sans text-primary tracking-wide
}


// global Angular Material overrides
.mat-stepper-horizontal-line {
  display: none
}

.mat-step-icon {
  border: 2px solid;
  border-color: #e2e8f0;
  border-radius: 50%;
}

.mat-horizontal-stepper-header-container {
  padding-bottom: 20px;
  @apply text-grey
}

.mat-step-header .mat-step-icon-selected {
  background-color: #4361EF;
  border-color: transparent;
  color: white;
}

@media (max-width: 639px) {
  .mat-horizontal-stepper-header-container {
    visibility: hidden;
    height: 0;
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 16px;
}

.mat-menu-panel.bulk-menu {
  min-width: 200px;
  max-width: 240px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 8px;
  outline: 0;
  background-color: #fff;
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 550;
}


.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: #4361EF;
  --mdc-checkbox-unselected-icon-color: #475467;
  --mdc-checkbox-unselected-pressed-icon-color: #4361EF;
  --mdc-checkbox-unselected-hover-icon-color: #475467;
  --mdc-checkbox-unselected-focus-icon-color: #475467;
  --mdc-checkbox-selected-icon-color: #EEF1FF;
  --mdc-checkbox-selected-hover-icon-color: #EEF1FF;
  --mdc-checkbox-selected-focus-icon-color: #EEF1FF;
}

.mdc-checkbox .mdc-checkbox__background {
  border-radius: 5px;
  border: 1px solid grey;
  color: grey;
}

.mat-mdc-tab-labels {
  width: 84px;
}

.remove-border-bottom .mat-mdc-tab-label-container {
  border-bottom-width: 1px;
  @apply border-b-grey-midGrey
}


.mat-mdc-tab-header .mat-mdc-tab {
  padding-bottom: 16px;
  @apply text-sm text-grey font-semibold;

  &.mdc-tab--active {
    @apply text-blue border-b-blue border-2;
  }
}

.mdc-tooltip {
  @apply bg-primary text-white text-xs p-2 rounded;
  position: relative;
  font-size: 12px;
}

.mdc-tooltip::after {
  @apply border-primary;
  border-bottom-width: 4px;
  width: 0;
  height: 0;
  content: '';
  position: absolute;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
}

.mdc-tooltip.tooltip-above {
  overflow: initial;
  margin-bottom: 0;

  &::after {
    bottom: -4px;
    right: calc(50% - 16px);
    transform: rotate(180deg);
  }
}


::ng-deep .mat-dialog-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-snack-bar {
  &.error {
    @apply bg-status-danger text-primary;
  }

  &.warning {
    @apply bg-status-warning text-primary;
  }

  &.info {
    @apply bg-status-info text-primary;
  }

  &.success {
    @apply bg-status-success text-primary;
  }
}


