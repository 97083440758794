:root {
  --icon-size-xs: 12px;
  --icon-size-sm: 14px;
  --icon-size-base: 16px;
  --icon-size-lg: 20px;
  --icon-size-xl: 22px;
  --icon-size-xxl: 24px;
}

.hvs-icon {
  @apply text-grey;
  &-sm {
    @extend .hvs-icon;
    width: var(--icon-size-sm) !important;
    height: var(--icon-size-sm) !important;
    font-size: var(--icon-size-sm);
  }
  &-base {
    @extend .hvs-icon;
    width: var(--icon-size-base) !important;
    height: var(--icon-size-base) !important;
    font-size: var(--icon-size-base);
  }
  &-lg {
    @extend .hvs-icon;
    width: var(--icon-size-lg) !important;
    height: var(--icon-size-lg) !important;
    font-size: var(--icon-size-lg);
  }
  &-xl {
    @extend .hvs-icon;
    width: var(--icon-size-xl) !important;
    height: var(--icon-size-xl) !important;
    font-size: var(--icon-size-xl);
  }
  &-xxl {
    @extend .hvs-icon;
    width: var(--icon-size-xxl) !important;
    height: var(--icon-size-xxl) !important;
    font-size: var(--icon-size-xxl);
  }
}
